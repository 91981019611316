import dayjs from "dayjs";
import cx from "classnames";
import styles from "./styles.module.scss";
import { categoriesMapper } from "../lib";
import { useContext, useEffect, useState } from "react";
import { FilterContext } from "../context";
import { Chart } from "../../customer/trend";
import { Avatar, Badge, Text } from "@radix-ui/themes";
import {
  ChatBubbleIcon,
  DotFilledIcon,
  EnvelopeOpenIcon,
  GlobeIcon,
  TimerIcon,
  ValueNoneIcon,
} from "@radix-ui/react-icons";

export const QAScore = ({ value }: { value: number }) => {
  if (!value || String(value) === "0") {
    return <>-</>;
  }
  return (
    <div className={styles.qaScore}>
      {value ? (
        <>
          {[...Array(5)].map((_, index) => {
            const isHalf = value > index && value < index + 1;
            return (
              <span
                key={index}
                className={
                  index < value
                    ? value < 2.5
                      ? isHalf
                        ? styles.half_red
                        : styles.red
                      : value < 3.5
                      ? isHalf
                        ? styles.half_yellow
                        : styles.yellow
                      : isHalf
                      ? styles.half_green
                      : styles.green
                    : styles.none
                }
              />
            );
          })}

          <div className={styles.qaScoreValue}>{value}</div>
        </>
      ) : (
        "-"
      )}
    </div>
  );
};

export const TTR = ({ value }: { value: number }) => {
  const convert = (time: number) => {
    return time > 60 * 24
      ? dayjs.duration(time, "minutes").format("D[D] H[H]")
      : dayjs.duration(time, "minutes").format("H[H] m[M]");
  };

  return (
    <div className={styles.ttr}>
      <TimerIcon />
      {value ? convert(value) : "-"}
    </div>
  );
};

export const CategoryName = ({ value }: { value: string }) => {
  return (
    <div className={styles.categoryName}>
      <span className={styles.iconContainer}>
        {categoriesMapper[value as keyof typeof categoriesMapper]?.icon}
      </span>
      {categoriesMapper[value as keyof typeof categoriesMapper]?.label ??
        "Unknown"}
    </div>
  );
};

export const KbCoverage = ({ value }: { value: string }) => {
  if (value === "NO_COVERAGE") {
    return (
      <div className={styles.notCovered}>
        <ValueNoneIcon />
        Not Covered
      </div>
    );
  }

  if (value === "PARTIAL") {
    return <div className={styles.partialCovered}>Partly Covered</div>;
  }

  return <div>Covered</div>;
};

export const Trend = ({
  value: qtyByDates,
}: {
  value: { date: string; count: number }[];
}) => {
  const { dateRange } = useContext(FilterContext);
  const [ticketCount, setTicketCount] = useState<number[]>([]);

  useEffect(() => {
    const ranges = [];
    const dateRangeMapping = {
      "7d": { value: 7, unit: "days" },
      "14d": { value: 14, unit: "days" },
      "1m": { value: 1, unit: "months" },
      "2m": { value: 2, unit: "months" },
    };

    const { value, unit } = dateRangeMapping[dateRange] || {
      value: 7,
      unit: "days",
    }; // Default to 7 days if not found

    for (let i = 0; i < 4; i++) {
      const endDate = dayjs()
        .subtract(i * value, unit as "days" | "months")
        .startOf("day");
      const startDate = endDate
        .subtract(value, unit as "days" | "months")
        .startOf("day");
      ranges.push({
        start: startDate.format("YYYY-MM-DD"),
        end:
          i === 0
            ? endDate.add(1, "day").format("YYYY-MM-DD")
            : endDate.format("YYYY-MM-DD"),
      });
    }

    const ticketCounts = ranges.map(({ start, end }) => {
      return qtyByDates.reduce((sum, { date, count }) => {
        const currentDate = dayjs(date);
        if (currentDate.isBefore(end) && currentDate.isAfter(start)) {
          return sum + count;
        }
        return sum;
      }, 0);
    });

    setTicketCount(ticketCounts);
  }, [dateRange]);

  return (
    <div>
      <Chart
        data={ticketCount
          .map((t, i) => ({
            month: 4 - i,
            count: t,
          }))
          .sort((a, b) => a.month - b.month)}
        width={60}
        height={30}
      />
    </div>
  );
};

export const TicketOverview = ({
  value,
}: {
  value: { subject: string; id: string };
}) => {
  return (
    <div className={styles.ticketOverviewContainer}>
      <Text wrap="wrap" size="2">
        {value?.subject}
      </Text>
      <Text color="gray" wrap="wrap" size="2" weight="light">
        #{value?.id}
      </Text>
    </div>
  );
};

export const Status = ({ value }: { value: string }) => {
  return (
    <div
      className={cx(styles.statusContainer, {
        [styles.red]: ["deleted"].includes(value),
        [styles.yellow]: ["pending", "hold"].includes(value),
        [styles.green]: ["solved", "closed"].includes(value),
        [styles.blue]: ["open"].includes(value),
      })}
    >
      <DotFilledIcon />
      {value}
    </div>
  );
};

export const Agent = ({ value }: { value: string }) => {
  return (
    <div className={styles.agent}>
      <Avatar
        size="1"
        fallback={value
          .split(" ")
          .map((x) => x[0])
          .join("")}
        color="gray"
      />
      {value}
    </div>
  );
};

export const Channel = ({ value }: { value: string }) => {
  return (
    <div className={styles.channel}>
      {value === "chat" || value === "native_messaging" ? (
        <ChatBubbleIcon />
      ) : value === "web" ? (
        <GlobeIcon />
      ) : (
        <EnvelopeOpenIcon />
      )}

      {value}
    </div>
  );
};
